.userDropdown {
  display: none;
  position: absolute;
  top: 92px;
  right: 50px;
  left: auto !important;
  z-index: 50;
  width: 300px;
  background-color: #fff;
  border-radius: 24px;
  padding: 24px;
}
.userDropdown h6 {
  font-weight: 500;
  font-size: 18px;
}
.userDropdown .greeting {
  font-weight: 500;
  color: #777e90;
}
.userDropdown.show {
  display: block;
}
.userDropdown ul {
  list-style: none;
}
.userDropdown li {
  border-radius: 12px;
}

.userDropdown li:hover > .li-item {
  color: #6d3190;
}

.userDropdown a {
  display: block;
  text-decoration: none;
  color: #212529;
  font-weight: 600;
}

.userDropdown .switch-theme {
  color: #212529;
  cursor: pointer;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
}
.userDropdown .switch-theme:hover {
  color: #6d3190;
}
