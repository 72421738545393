#dropdown-basic {
  width: 100% !important;
  text-align: left !important;
  border-radius: 50% !important;
  background-color: white !important;
  color: #23262f !important;
  height: 48px;
  font-weight: 500;
  position: relative;
}

.custom-dropdown {
  margin-bottom: 0px !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-border {
  border: 1px solid #777e91 !important;
}

.dropdown-menu-center {
  z-index: 9 !important;
  right: auto !important;
  background: #fcfcfd !important;
  box-shadow: 0px 0px 24px -48px rgba(31, 47, 70, 0.1) !important;
  border-radius: 12px !important;
  -webkit-transform: translate3d(-40%, 50px, 0px) !important;
  -o-transform: translate3d(-40%, 50px, 0px) !important;
  transform: translate3d(-40%, 50px, 0px) !important;
  padding: 9px 16px 9px 16px !important;
  width: 200px !important;
}
.dropdown-menu-item {
  padding: 14px 0px 14px 0px !important;
  border-bottom: 1px solid #e6e8ec !important;
  .dropdown-menu-item-style {
    font-weight: 700;
    font-size: 14px;
    color: #777e91;
    .image {
      padding-right: 10px !important;
    }
  }
}

.dropdown-menu-item:last-child {
  border: none !important;
}
