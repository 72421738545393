.ga-boarder {
  border: 1px solid red;
  width: auto;
}
.ga-navs {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.ga-navs-btn {
  padding-top: 30px;
  padding-bottom: 30px;
}

.ga-navs .nav-pills .nav-link.active,
.ga-navs .nav-pills .show > .nav-link {
  background-color: #353945;
}
.ga-navs .nav-pills {
  border-radius: 50px;
  border: 1px solid #e6e8ec;
}
.ga-navs .nav-pills .nav-link {
  border-radius: 50px;
  font-size: 14px;
  color: #777e90;
  background: transparent;
}

.gallery-artist-bg {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.btn-gray,
.btn-gray:active,
.btn-gray:visited {
  border-radius: 90px !important;
  color: #23262f !important;
  border: 2px solid #e6e8ec !important;
  background-color: #fdfcfd !important;
  margin-left: 20px;
}

.btn-gray:hover {
  opacity: 0.5;
  margin-left: 20px;
}

.ga-back {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding-top: 29px;
}

.ga-title {
  font-weight: 600;
  font-size: 40px;
  margin: 0;
}

.ga-divider {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 40px;
  color: #adadad;
}
.ga-sub-text {
  font-weight: 600;
  font-size: 24px;
  line-height: 56px;
  color: #23262f;
}

.ga-sub-title {
  font-weight: 600;
  font-size: 24px;
}

.ga-no-data-found {
  padding-top: 100px;
}

.sub-text {
  padding-top: 20px;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: #777e91;
}

.mb-436px {
  margin-top: 436px;
}

.vr-title {
  font-weight: 600;
  font-size: 40px;
  line-height: 56px;
  letter-spacing: -0.02em;
  color: #23262f;
}

.vr-sub-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 56px;
  letter-spacing: -0.02em;
  color: #23262f;
}
