.list-group-style {
  .list-group-item {
    padding: 0;
    margin-bottom: 16px;
  }
  .list-item-style {
    background: #ffffff;
    box-shadow: 4px 4px 29px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-inline-end: 25px;
    .profile {
      display: flex;
      flex-direction: row;
      align-self: center;
    }
    .profile-image {
      padding: 7px;
      width: 96px;
      height: auto;
    }
    .text {
      display: flex;
      flex-direction: column;
      align-self: center;
      margin-inline-start: 25px;
      .t1 {
        font-weight: 350;
        font-size: 24px;
        line-height: 32px;
        color: #141416;
      }
      .t2 {
        font-weight: 350;
        font-size: 16px;
        line-height: 24px;
        color: #353945;
      }
      .t3 {
        font-weight: 350;
        font-size: 12px;
        line-height: 20px;
        color: #777e91;
      }
    }
    .actions {
      padding: 10px;
      border: 2px solid #e6e8ec;
      border-radius: 50%;
      height: 48px;
      width: 48px !important;
      color: #777e91;
    }
  }
  .list-group-item:last-child {
    padding-bottom: 0;
  }
}
