.galleries {
  padding: 28px 30px 30px 30px !important;

  .title {
    font-weight: 400;
    font-size: 40px;
    color: #23262f;
  }
  .sub-title-1 {
    font-weight: 400;
    font-size: 32px;
    color: #23262f;
    padding-top: 32px !important;
  }
  .sub-title-2 {
    padding-top: 60px !important;
    font-weight: 400;
    font-size: 32px;
    color: #23262f;
  }
  .featured-gallery {
    position: relative;
    color: white;
    .bottom-right {
      position: absolute;
      bottom: 28px;
      right: 40px;
    }
  }
}

#loader {
  display: flex;
  justify-content: center;
  z-index: 99999;
}
