.border-btm-0 {
  border-bottom: none !important;
}
.create-section-title {
  font-size: 24px;
  line-height: 56px;
  letter-spacing: -0.02em;
  color: #23262f;
}

.textarea-h-100 {
  height: 100px !important;
}

.dialogClass {
  max-width: 704px !important;
}

.modal-container-p {
  padding: 0 19px !important;
}
.title-p {
  padding: 1.8rem !important;
}

.uploadCardLabelContainer {
  margin-left: 40px !important;
}

.previewContainer {
  margin-left: 40px !important;
}

.create-section-label {
  font-size: 14px;
  line-height: 24px;
  font-weight: 350;
  color: #777e90;
  text-transform: uppercase;
}
.create-page-input-field::placeholder {
  color: #000;
  font-weight: 350 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.select-flag {
  border: 2px solid #e6e8ec;
  border-radius: 12px;
  color: #9d9d9d;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.flag-class {
  max-width: 257px;
}

.required {
  color: red !important;
}
.social-icon-wrapper {
  position: absolute;
  left: 15px;
  right: unset;
  bottom: 0;
  top: 0;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rtl .social-icon-wrapper {
  position: absolute;
  right: 15px;
  left: unset;
  bottom: 0;
  top: 0;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-icon {
  border-radius: 50%;
  width: 27px;
  height: 27px;
  background-color: #6d3190;
  display: flex;
  justify-content: center;
  align-items: center;
}
.social-divider {
  width: 1px;
  height: 60%;
  background-color: #e6e8ec;
}
.social-input {
  padding-inline-start: 60px;
}
