.notificationsDropdown {
    display: none;
    position: absolute;
    top: 92px;
    right: 50px;
    left: unset !important;
    z-index: 50;
    width: 400px;
    background-color: #fff;
    border-radius: 32px;
    padding: 24px 16px;
}
.notificationsDropdown.show {
    display: block;
}
.notificationsDropdown h3 {
    font-size: 32px;
    font-weight: 500;
}
.notificationsDropdown .img-thumb {
    width: 75px;
    height: 75px;
    object-fit: cover;
}
.notificationsDropdown .notification {
    padding: 16px;
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.3s;
}
.notificationsDropdown .notification:hover {
    background-color: #f4f5f6;
}
.notificationsDropdown p {
    color: #777e90;
    font-weight: 500;
}
