.dropdownOverlay {
  position: relative;
  width: auto;
  margin-top: 10px;
  max-height: 50px;
  min-width: 20rem;
  width: 100%;
}

.dropdownView {
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(31, 47, 70, 0.25);
  border-radius: 5px;
  display: block;
  padding: 10px !important;
  max-height: 200px;
  overflow: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  width: 90%;
  z-index: 10;
  position: relative;
  top: 0;
}

.dropdownView::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.dropdownItem {
  border-bottom: 1px solid #e6e8ec;
  list-style-type: none;
  cursor: pointer;
  padding: 10px;
}

.dropdownItem:hover {
  background-color: #f6f6f6;
}
.close-search-result {
  text-align: end;
}
.close-search-result:hover {
  cursor: pointer;
}
.searchIcon {
  display: block;
  width: 48px;
  height: 48px;
  position: relative;
  left: -45px;
  z-index: 10;
  background: url(/public/search.svg) center center no-repeat;
}
.rtl .searchIcon {
  left: unset;
  right: -45px;
}
