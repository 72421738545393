.no-galleries-found {
  padding-top: 80px;
  padding-bottom: 80px;
  .text {
    font-weight: 350;
    font-size: 22px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.02em;
    margin: 33px;
  }
  .btnText {
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    text-align: center;
    /* Neutrals/8 */

    color: #fcfcfd;
  }
}
