.row {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.social-icon-button {
  border-radius: 50%;
  width: 27px;
  height: 27px;
  background-color: #6d3190;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-start: 12px;
}
.artist-info {
  padding: 25px 32px 25px 32px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.78);
  backdrop-filter: blur(34px);
  margin-top: -100px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}
