.search-form {
  padding-top: 86px;
  padding-bottom: 45px;
}
.search-form input:focus {
  outline: none;
}
.search-form .btn-purple {
  position: relative;
  left: -40px;
  height: 40px;
  width: 40px;
}
.search-form .btn-purple.rounded-full {
  border-radius: 100%;
}
.search-form .big-search {
  padding-bottom: 30px;
  border-radius: 0;
  border: 0;
  border-bottom: #e6e8ec solid 1px;
  font-size: 24px;
}
.search-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url(/public/search-white.svg) 0 0 no-repeat;
}

.rc-slider
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #6d3190;
  box-shadow: 0 0 0 5px #6d3190;
}
.rc-slider .rc-slider-handle {
  border: solid 2px #6d3190;
}
.rc-slider .rc-slider-handle:hover {
  border-color: #6d3190;
}
.rc-slider .rc-slider-track {
  background-color: #6d3190;
}
.dropdown-label {
  font-size: 12px;
  color: #b1b5c3;
  text-transform: uppercase;
  margin-bottom: 8px;
}
.range-min-max {
  padding: 8px 24px;
  border: 2px solid #e5e5e5;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
}
.span {
  border-radius: 48px;
  margin: 8px;
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
  background-color: #f4f5f6;
  color: #23262f;
  padding: 10px 16px;
}
.reset-filter-span {
  margin: 8px;
  font-weight: 600;
  font-size: 14px;
  display: inline-block;
  color: #23262f;
  padding: 10px 16px;
}
.close-filter:hover {
  cursor: pointer;
}
.close-filter:hover + span {
  color: #6d3190;
}
/* display serch box */
.mx-x-lg-0{
  z-index: 1010;
}
.aa-Form{
  height: 38px;
}