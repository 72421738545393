.profile-navs {
  padding-top: 30px;
  padding-bottom: 30px;
}
.profile-navs .nav-pills .nav-link.active,
.profile-navs .nav-pills .show > .nav-link {
  background-color: #353945;
}
.profile-navs .nav-pills .nav-link {
  border-radius: 50px;
  font-size: 14px;
  color: #777e90;
  background: transparent;
}
.followers-btn {
  background-color: transparent;
  border: none;
  color: #777e91;
  text-align: center;
}
.followers-btn:hover span {
  color: #6d3190;
}
.followers-btn span {
  color: black;
  font-size: 18px;
  display: block;
}
.profile-bg {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.profile-info {
  padding: 32px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.78);
  backdrop-filter: blur(34px);
  margin-top: -100px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}
.profile-info h3 {
  font-weight: 600;
  font-size: 24px;
}
.profile-info .profile-message {
  font-size: 14px;
  color: #777e90;
}
.profile-info .member-since {
  font-size: 16px;
  color: #23262f;
  margin-inline-start: 5px;
  font-weight: 350;
}
.profile-sections {
  min-height: 100vh;
}

.searchIcon {
  display: block;
  width: 48px;
  height: 48px;
  position: relative;
  left: -45px;
  z-index: 10;
  background: url(/public/search.svg) center center no-repeat;
}
.rtl .searchIcon {
  left: unset;
  right: -45px;
}

.filterBar {
  border: 0.6 solid #d5d5d5;
  border-radius: 8px;
  background-color: #f9f9fb;
  padding: 10px;
}
th{
  font-weight: 400;
}
