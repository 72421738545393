.gallery-sub-text {
  font-size: 48px;
  line-height: 47px;
  font-weight: 400;
  /* Active text-Dropdown */
  text-align: center;
  color: #0f0f0f;
}
.purple-text {
  color: #6d3190;
}
.gallery-card {
  background: #ffffff;
  box-shadow: 0px 100px 100px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.about-content {
  font-size: 20px;
  color: #858585;
  margin-top: 10px;
}
.user-xlg-rounded {
  width: 120px;
  height: 120px;
  border-radius: 100px;
}
.sup-title {
  font-size: 20px;
  font-weight: 400;
  color: #000;
}
.using-reasons {
  padding-top: 60px;
  height: 700px;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #000;
  background: url(../../../../icons/oval.png) center top no-repeat;
  background-size: 100%;
  box-shadow: 0px 0px 100px rgba(255, 185, 205, 0.2);
  background: rgba(255, 226, 236, 0.2);
  & > div {
    width: 284px;
    background-color: #fff;
    padding: 45px;
    margin: 0 auto 20px;
    box-shadow: 0px 100px 100px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
}
.create-gallery-button {
  font-size: 16px;
  line-height: 16px;
  color: #fcfcfd;
  padding: 10px;
}
.gallery-header-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 697px;
  z-index: -1;
}
.gallery-header-overlay {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.77) 13.54%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 697px;
}
.step-number {
  font-size: 60px;
  line-height: 1em;
  color: #f2f2f2;
}
.how-box {
  padding: 14px 20px;
  width: 264px;
  border-radius: 4px;
  box-shadow: #e5e5e5 4px 19px 48px;
  & img {
    margin-bottom: 30px;
  }
}
.choose-container {
  width: 80%;
}
.choose-box {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  text-align: center;
  font-size: 20px;
  padding: 24px 20px;
  width: 284px;
  height: 125px;
  border-radius: 4px;
  font-weight: 350;
  box-shadow: #e5e5e5 4px 19px 48px;
}

.gallery-header-text {
  text-align: center;
  .purple {
    color: #6d3190;
  }
  position: absolute;
  color: #fff;
  top: 200px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-text {
  font-size: 60px;
  color: #fff;
  font-weight: 400;
  line-height: 1.2em;
}
.title-sub-text {
  font-weight: 350;
  font-size: 24px;
  max-width: 75%;
  text-align: center;
  color: #fff;
  line-height: 1.5em;
}

.create-gallery {
  padding-left: 160px;
  .title {
    font-weight: 600;
    font-size: 40px;
  }
  .sub-title {
    font-weight: 600;
    font-size: 24px;
  }
}

.gallery-page-form-group {
  margin-bottom: 36px;
}

.gallery-page-label {
  font-size: 12px;
  text-transform: uppercase;
  color: #b1b5c4;
}

.gallery-page-input {
  border: #e6e8ec solid 2px !important;
  border-radius: 12px !important;
  height: 48px;
}

.gallery-page-input-area {
  border: #e6e8ec solid 2px !important;
  border-radius: 12px !important;
  height: 100px;
}

.gallery-upload-text {
  .text {
    font-weight: 500;
    font-size: 16px;
  }
  .sub-text {
    font-weight: 400;
    font-size: 12px;
    max-width: 359px;
  }
}

.gallery-upload-field {
  margin-bottom: 36px;
  padding: 24px;
  gap: 10px;
  background: #f4f5f6;
  border-radius: 16px;
  border: none;
  .text {
    font-size: 14px;
    margin-left: 10px;
  }
}

.m-width {
  max-width: 800px;
}

.btn-padding {
  padding-top: 30px;
  padding-bottom: 160px;
}
.gallery-main-content {
  padding-top: 697px;
}
@media (max-width: 992px) {
  .gallery-header-image {
    object-fit: cover;
  }
  .choose-container {
    width: 100%;
  }
  .title-text {
    font-size: 50px;
  }
  .title-sub-text {
    font-size: 22px;
  }
}
@media (max-width: 480px) {
  .title-text {
    font-size: 40px;
  }
  .title-sub-text {
    font-size: 20px;
  }
  .gallery-header-text {
    top: 190px;
  }
}
