.artists {
  padding: 28px 30px 30px 30px !important;

  .title {
    font-weight: 400;
    font-size: 40px;
    color: #23262f;
  }
  .sub-title-1 {
    font-weight: 250;
    font-size: 18px;
    line-height: 24px;
    color: #676767;
    padding-top: 41px;
  }
  .sub-title-2 {
    font-weight: 400;
    padding-top: 48px;
    font-size: 40px;
    color: #23262f;
    text-align: center;
  }
  .artist-photo-cover {
    padding-top: 32px;
    .cover-image {
      width: 100%;
      height: 449px;
    }
  }
  .social-icon-button {
    border-radius: 50%;
    width: 27px;
    height: 27px;
    background-color: #6d3190;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-inline-start: 12px;
  }
}
