.btn-close {
  background: transparent url("../../../../../../assets/icons/close-square.svg") !important;
}

.modal-body {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: -0.02em;
  color: #000000;
}

.contentClass {
  border-radius: 16px !important;
  padding: 28px;
}

.body {
  padding: 29px 0 24px 0 !important;
  .btn-custom {
    padding-top: 8px !important;
  }
}

.md-header {
  padding: 16px 10px 16px 0 !important;
}
