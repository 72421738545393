body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-main: #6d3190;
  --color-grey-dark: #777e91;
  --color-grey-medium: #b1b5c4;
  --color-grey-light: #e6e8ec;
  --color-grey-lighter: #f4f5f6;
}

/* --------- layout --------- */
.main-section {
  padding-top: 128px;
  padding-bottom: 128px;
}
@media (max-width: 768px) {
  .main-section {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

/* --------- buttons --------- */
