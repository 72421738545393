.uploadCardLabelContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #f4f5f6;
  border-radius: 12px;
  width: 160px;
  height: 80px;
  padding: 20px;
  gap: 10px;
}

.uploadLabelText {
  color: #23262f;
}

.previewContainer {
  position: relative;
  width: 160px;
  height: 80px;
  background-color: #f6f6f6;
  border-radius: 12px;
}
.previewContainer .previewImage {
  display: block;
  width: 160px;
  height: 80px;
  border-radius: 12px;
}
.previewContainer .closeIcon {
  position: absolute;
  cursor: pointer;
  right: 6px;
  bottom: 6px;
}
