@font-face {
  font-family: "dubai";
  src: url("https://www.fontstatic.com/fonts/dubai-bold/dubai-bold.eot?#iefix");
  src: local("دبي"), local("dubai"),
    url("https://www.fontstatic.com/fonts/dubai-bold/dubai-bold.woff")
      format("woff");
  font-weight: 450;
}

@font-face {
  font-family: "dubai";
  src: url("https://www.fontstatic.com/fonts/dubai-medium/dubai-medium.eot?#iefix");
  src: local("دبي"), local("dubai"),
    url("https://www.fontstatic.com/fonts/dubai-medium/dubai-medium.woff")
      format("woff");
  font-weight: 400;
}

@font-face {
  font-family: "dubai";
  src: url("https://www.fontstatic.com/fonts/dubai/dubai.eot?#iefix");
  src: local("دبي"), local("dubai"),
    url("https://www.fontstatic.com/fonts/dubai/dubai.woff") format("woff");
  font-weight: 350;
}

@font-face {
  font-family: "dubai";
  src: url("https://www.fontstatic.com/fonts/dubai-light/dubai-light.eot?#iefix");
  src: local("دبي"), local("dubai"),
    url("https://www.fontstatic.com/fonts/dubai-light/dubai-light.woff")
      format("woff");
  font-weight: 325;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
span,
a {
  font-weight: 350;
  font-family: "dubai", sans-serif;
}

.rtl body,
.rtl h1,
.rtl h2,
.rtl h3,
.rtl h4,
.rtl h5,
.rtl h6,
.rtl div,
.rtl span,
.rtl a {
  font-weight: 350;
  font-family: "dubai", sans-serif;
}

input:focus,
select:focus {
  box-shadow: none !important;
  border-color: #c0c1c5 !important;
}

img {
  max-width: 100%;
}

.app-header {
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.9);
  /* background-color: rgba(0, 0, 0, 1); */
  /* opacity: 0.9; */
  padding-top: 15px;
  padding-bottom: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}

.page-header-xs {
  font-size: 15px;
  font-weight: 400;
}

.page-header-sm {
  font-size: 17px;
  font-weight: 400;
}

.page-header-md {
  font-size: 28px;
  font-weight: 400;
}

.page-header {
  font-size: 40px;
  font-weight: 400;
}

.page-header-lg {
  font-size: 60px;
  font-weight: 400;
}

.home-cover-image {
  background-size: 100% 724px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.77) 13.54%,
      rgba(0, 0, 0, 0) 100%
    ),
    url("../src/assets/images/cover.png");
}

.partners-cover-image {
  background-size: 100% 554px;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.77) 13.54%,
      rgba(0, 0, 0, 0) 100%
    ),
    url("../src/assets/images/partners-cover.png");
}

.btn {
  border-radius: 50px;
  font-weight: 350;
}

@media (min-width: 992px) {
  .nav-border-left {
    border-left: #e6e8ec solid 1px;
  }
}

.navbar-expand .nav-border-left.navbar-nav .nav-link {
  padding-left: 14px;
  padding-right: 14px;
}

.navbar-expand .form-control:focus {
  box-shadow: none;
  border-color: #7e7e7e;
}

/* react-bootsrtab navdropdown overide */
.navbar-expand-lg .navbar-nav .dropdown-menu {
  position: absolute;
}

.app-header .dropdown-toggle::after {
  display: none !important;
}

.dropdown-toggle::after {
  margin-inline-start: 5px;
}
.nft-menu .dropdown-menu.show {
  border-radius: 8px;
  padding: 8px;
  background-color: #fff;
}
.nft-menu-dark .dropdown-menu.show {
  border-radius: 8px;
  padding: 8px;
  background-color: #000;
  border: 1px solid #fff;
}

.user-menu .dropdown-menu.show {
  border-radius: 24px;
  padding: 24px;
  width: 300px;
}

.rtl .breadcrumb-item + .breadcrumb-item:before {
  float: right;
  padding-right: 0;
  padding-left: 8px;
}

.rtl .breadcrumb-item + .breadcrumb-item {
  padding-left: 0;
  padding-right: 8px;
}

@media (max-width: 676px) {
  .user-menu .dropdown-menu.show {
    width: 250px;
  }
}

@media (max-width: 480px) {
  .user-menu .dropdown-menu.show {
    width: 200px;
    font-size: 14px;
  }
}

a.dropdown-item:hover {
  color: #6d3190;
  background-color: #f7f7f7;
  border-radius: 12px;
}

.form-control.search-field {
  position: relative;
  background: #ffffff;
  border: #e8e8e8 solid 0.66px;
  border-radius: 6.63px;
  z-index: 1;
}

.form-control::-webkit-input-placeholder {
  color: #949494;
  font-weight: 325;
  font-size: 12px;
}

.search-btn {
  position: relative;
  left: -35px;
  z-index: 10;
  background: url(/public/search.svg) center center no-repeat;
}

.btn.btn-golden {
  background: #b58937;
  border: #b58937 solid 1px;
  color: #fff !important;
  font-weight: 350;
}

.btn.btn-primary {
  background: #6d3190;
  border: #6d3190 solid 1px;
  color: #fff !important;
  font-weight: 350;
}

.btn.btn-primary:disabled,
.btn.btn-primary.disabled {
  background: #6e517e;
  border: #6d3190 solid 1px;
  color: #fff !important;
  font-weight: 350;
}

.btn.gradient {
  background-image: linear-gradient(
    to right,
    #6d3190 0%,
    #a63fcd 50%,
    #bc60f1 100%
  );
  border: 0px;
}

.btn.gradient-golden {
  background-image: linear-gradient(
    to right,
    #b69a66 0%,
    #b58937 50%,
    #916e2c 100%
  );
  border: 0px;
}

.btn-check:focus + .btn-primary,
.btn.btn-primary:focus {
  background: #6d3190;
  border: #6d3190 solid 1px !important;
  box-shadow: none;
}

.btn.btn-primary,
.btn.btn-outline-secondary {
  height: 38px;
  padding: 7px 20px 5px !important;
  border-radius: 50px;
}

.btn.btn-outline-secondary {
  border: #7e7e7e solid 1px !important;
}

.btn.btn-secondary-outline {
  border: #e6e8ec solid 1px !important;
}

.btn.btn-secondary-outline:hover {
  border: #7e7e7e solid 1px !important;
}

.btn.nav-link:focus,
.btn.nav-link:hover {
  border: #7e7e7e solid 1px;
  border-radius: 50px;
  color: #fff !important;
}

.notifications {
  width: 40px;
  height: 40px;
  background: url(/public/bell.svg) center center no-repeat;
}

body a:hover {
  /* color: #6d3190; */
}

body a.tag:hover {
  color: #fff;
  text-decoration: none;
  opacity: 0.8;
}

.btn.btn-purple-outline {
  /* height: 38px;
  color: #6d3190;
  font-weight: 350;
  padding: 7px 20px 5px !important;
  border-radius: 50px;
  border: #6d3190 solid 2.5px;
  background-color: transparent; */
  padding: 5px 15px 5px;
  font-weight: 400;
  color: #6d3190;
  border-radius: 50px;
  border: #6d3190 solid 1px;
  text-decoration: none;
}

.btn.btn-purple-outline :hover {
  color: red;
  opacity: 0.8;
}

.btn.btn-purple-outline .dollar {
  font-weight: 400 !important;
}

.user-md-rounded {
  width: 48px;
  height: 48px;
  border-radius: 100px;
}

.user-60-rounded {
  width: 60px;
  height: 60px;
  border-radius: 500px;
}

.user-40-rounded {
  min-width: 40px;
  width: 40px;
  height: 40px;
  border-radius: 500px;
}

.user-30-rounded {
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 500px;
}

.home-hero a {
  text-decoration: none;
}

.avatar {
  text-decoration: none;
  width: 50px;
}

.buy-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: url(/public/square.svg) center center no-repeat #6d3190;
}

.creator-buy-box a {
  text-decoration: none;
}

a .creator-box {
  font-size: 14px;
  font-weight: 350;
  color: #151515;
}

a .creator-box .text-muted {
  font-size: 14px;
  font-weight: 350;
}

.items-count {
  padding: 2px 6px;
  border: #e6e8ec solid 2px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}

.collection-info h4 {
  font-size: 24px;
}

.collection-info a {
  font-size: 14px;
  color: #353945;
  text-decoration: none;
}

.collection-info span {
  cursor: pointer;
}

.radius-8 {
  border-radius: 8px;
}

.user-sm-rounded {
  width: 24px;
  height: 24px;
  border-radius: 50px;
}

.user-lg-rounded {
  width: 56px;
  height: 56px;
  border-radius: 100px;
}

.collection {
  width: 100%;
  /* max-width: 256px; */
}

.owners {
  padding-left: 12px;
}

.owners a {
  width: 24px;
  height: 24px;
  margin-left: -12px;
}

.radius-50,
.btn.radius-50 {
  border-radius: 50px;
  border: #fff solid 2px;
}

.radius-10 {
  border-radius: 10px;
}

.h-full {
  height: 96%;
}

.card-bid {
  margin-top: 0.75rem;
  padding-top: 0.65rem;
  border-top: #e6e8ec solid 1px;
  font-size: 12px;
  line-height: 20px;
}

.border-left-gray {
  border-left: #e6e8ec solid 1px;
}

.rtl .border-left-gray {
  border-left: 0;
  border-right: #e6e8ec solid 1px;
}

.collection.card {
  margin-bottom: 20px;
  border: 0;
  box-shadow: 2px 2px 11px #e0e0e0;
  padding: 20px;
  border-radius: 12px;
}

.creator-img {
  position: relative;
}

a.creator-item {
  color: #23262f;
  text-decoration: none;
  border-bottom: 1px solid #e6e8ec;
  padding-top: 24px;
  padding-bottom: 24px;
}

@media (max-width: 1200px) {
  a.creator-item {
    border: none;
    margin-right: 0px;
    min-width: 280px;
    padding-top: 12px;
    padding-bottom: 10px;
  }

  .lang {
    width: 100%;
  }
}

.creator-img span {
  position: absolute;
  top: -4px;
  left: -4px;
  background: #141416;
  border-radius: 25px;
  width: 24px;
  height: 24px;
  border: #fcfcfd solid 2px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  line-height: 20px;
  z-index: 2;
}

.gray-container {
  background-color: #f4f5f6;
}

.gray-containe h5 {
  font-size: 24px;
  font-weight: bold;
  font-size: 32px;
  color: #777e90;
}

.seller-box {
  margin-bottom: 40px;
  margin-right: 20px;
  padding: 20px;
  width: 240px;
  height: 247px;
  background: #fff;
  border-radius: 16px;
}

.seller-box:last-child {
  margin-right: 0;
}

.rounded-full {
  border-radius: 100%;
}

.plus-btn {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(/public/plus.svg) 0 0 no-repeat;
}

.share {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(/public/share.svg) 0 0 no-repeat;
}

.radius-50 {
  border-radius: 50px;
}

a.tag {
  padding: 2px 6px;
  border-radius: 20px;
  color: #fff;
  background-color: #23262f;
  text-decoration: none;
  font-size: 11px;
}

.tag.blue {
  background: #3772ff;
}

.tag.purple {
  background: #9757d7;
}

.tag.green {
  background: #45b26b;
}

.tag.black {
  background: #23262f;
}

.tag .winner {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(/public/winner.svg) 0 0 no-repeat;
}

.tag .donut {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(/public/donut.svg) 0 0 no-repeat;
}

.tag .lightning {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(/public/lightning.svg) 0 0 no-repeat;
}

.seller-info {
  margin-top: 10px;
  padding-top: 10px;
  border-top: #e6e8ec solid 1px;
}

.seller-info a {
  line-height: 24px;
  font-size: 14px;
  text-decoration: none;
  color: #23262f;
  font-weight: 600;
}

.seller-info a .text-muted {
  font-weight: 300;
}

.position-relative {
  position: relative;
}

.tiny-logo {
  position: absolute;
  display: inline-block;
  width: 21px;
  height: 21px;
  bottom: 0;
  right: 0;
  background: url(/public/tiny-logo.svg) 0 0 no-repeat;
  z-index: 5;
}

.bordered-title {
  padding-top: 50px;
  border-bottom: #e6e8ec solid 1px;
  padding-bottom: 20px;
  margin-bottom: 30px;
}

.back-arrow {
  position: relative;
  top: 8px;
  width: 40px;
  height: 40px;
  background: url(/public/arrow-left.svg) center center no-repeat;
}

.wallet-list {
  list-style: none;
}

.wallet-list a {
  display: block;
  padding: 22px 0;
  color: #23262f;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
}

.wallet {
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-right: 24px;
  background: url(/public/wallet.svg) center center no-repeat #9757d7;
  border-radius: 50px;
}

.wallet.airgap-wallet {
  background-color: #5a8aff;
}

.wallet.atomex-wallet {
  background-color: #45b26b;
}

.wallet.spire-wallet {
  background-color: #ef466f;
}

footer {
  background: #fcfcfd;
  line-height: 2rem;
}

footer h4 {
  margin-bottom: 40px;
}

.footer-box {
  padding-top: 54px;
  padding-bottom: 54px;
}

footer a {
  color: #7f8596;
  text-decoration: none;
}

footer a.accept-link {
  color: #6d3190;
  font-weight: bold;
}

.home-hero {
  padding: 92px 0;
  border-bottom: #e6e8ec solid 1px;
}

.spacer-110 {
  padding-top: 120px;
}

@media (max-width: 768px) {
  .spacer-110 {
    padding-top: 85px;
  }
}

.slogan {
  margin-bottom: 6px;
  font-size: 20px;
  font-weight: 350;
  line-height: 1em;
  text-transform: uppercase;
  color: #ffffff;
  /* font-family: "Gotham Narrow"; */
}

.home-title {
  font-size: 54px !important;
  color: #ffffff;
  letter-spacing: -1px;
  font-weight: 400;
  line-height: 76.8px;
}

@media (min-width: 576px) {
  .home-title {
    font-size: 64px !important;
    font-weight: 400;
    line-height: 76.8px;
    letter-spacing: -1px;
  }
}

.hero-item h2 {
  font-size: 48px;
  line-height: 64px;
  font-weight: 500;
  color: #23262f;
  text-transform: capitalize;
}

.rounded-btn {
  border-radius: 50px !important;
}

.btn.btn-purple {
  background: #6d3190;
  color: #fff;
}

.btn.btn-purple:hover {
  color: #fff;
  opacity: 0.8;
}

.btn.btn-primary:hover {
  background: #6d3190;
  border-color: #6d3190;
  opacity: 0.8;
}

.btn.btn-purple.cta-btn {
  margin-top: 20px;
  border-radius: 100px;
  padding: 16px 28px;
}

.current-bid-box {
  width: 320px;
  border: #e6e8ec solid 2px;
  border-radius: 24px;
  padding: 34px;
  box-shadow: #e6e8ec 0 20px 20px;
}

.current-bid {
  font-weight: 350;
  font-size: 24px;
  line-height: 27px;
  margin-top: 10px;
}

.divider {
  height: 100%;
  width: 1px;

  border: 1.5px solid;
  border-top: 0;
  border-bottom: 0;
  border-left: 0;
  border-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(226, 226, 226, 1) 55%,
    rgba(255, 255, 255, 0) 100%
  );
  border-image-slice: 1;
}

.price-value {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #777e91;
}

.timing-number {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
}

.mw-cta {
  width: 320px;
}

.home-box {
  padding-top: 96px;
  padding-bottom: 96px;
}

.right-arrow-tiny {
  display: block;
  float: right;
  width: 16px;
  height: 24px;
  background: url(/public/right-arrow-tiny.svg) center center no-repeat;
}

.bidding-items .btn.btn-secondary-outline:hover {
  background: #6d3190;
  border-color: #6d3190 !important;
  color: #fff;
}

.cover-photo .container {
  position: relative;
}

.cover-actions {
  position: absolute;
  bottom: 30px;
  right: 0;
  z-index: 10;
}

@media (max-width: 768px) {
  .cover-actions {
    bottom: 64px;
  }
}

.cover-actions a {
  display: inline-block;
  padding: 8px 20px;
  border-radius: 50px;
  border: #777e90 solid 2px;
  color: #fff;
  text-decoration: none;
}

.cover-actions a:hover {
  border-color: #fff;
  text-decoration: none;
  color: #fff;
}

.photo-icon,
.edit-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
}

.photo-icon {
  background: url(/public/photo.svg) 0 0 no-repeat;
}

.edit-icon {
  background: url(/public/edit.svg) 0 0 no-repeat;
}

.profile-box {
  margin-top: -126px;
  padding: 32px 12px;
  border: #e6e8ec solid 1px;
  border-radius: 16px;
  text-align: center;
  background: #fff;
}

.profile-box a {
  color: #23262f;
  text-decoration: none;
}

@media (max-width: 768px) {
  .profile-box {
    margin-top: -32px;
  }
}

.user-photo {
  padding: px;
}

.profile-box h3 {
  font-size: 24px;
  letter-spacing: -0.5px;
}

.wallet-address-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: url(/public/wallet-address.svg) 0 0 no-repeat;
}

.user-slogan {
  color: #b1b5c3;
  font-size: 9px;
  font-weight: bold;
}

.dribble {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url(/public/globe.svg) 0 0 no-repeat;
}

.vertical-spacer {
  padding-top: 92px;
  padding-bottom: 92px;
}

.crypto-price,
.usd-price {
  min-width: 80px;
  padding: 4px 8px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
}

.crypto-price {
  border: #6d3190 solid 2px;
  color: #6d3190;
}

.usd-price {
  border: #e6e8ec solid 2px;
  color: #777e90;
}

/* NFT */

.nft-cont-img2 h3 {
  /* position: absolute; */
  /* width: 394px; */
  /* height: 48px; */
  /* left: 631px; */
  /* top: 177px; */
  font-style: normal;
  /* font-size: 40px; */
  /* line-height: 48px; */
  letter-spacing: -0.01em;
  color: #23262f;
}

.rounded-0 {
  border-radius: 0 !important;
}

.nft-name {
  font-size: 24px;
  line-height: 24px;
  color: #23262f;
  font-weight: 400;
}

.nft-item a {
  text-decoration: none;
}

.link {
  text-decoration: none;
}

a.nft-price {
  color: #777e90;
}

a.nft-price .dollar,
.nft-stock {
  color: #353945;
}

.nft-ul {
  list-style-type: none;
  display: flex;
  width: max-content !important;
  border: 3px solid #e6e8ec;
  color: #777e90;
  border-radius: 20px;
  height: 40px;
  cursor: pointer;
}

.nft-li-desc {
  margin-left: -25px;
}

.nft-title {
  color: #9095a4;
}

.nft-desc {
  color: #9095a4;
}

.nft-detail {
  color: #9095a4;
}

.nft-tabs {
  padding: 5px;
  border: #e6e8ec solid 2px;
  border-radius: 50px;
}

.nav-pills.gray-tabs .nav-link {
  border-radius: 50px;
  color: #777e90;
  font-size: 14px;
}

.nav-pills.gray-tabs .nav-link.active,
.nav-pills.gray-tabs .show > .nav-link {
  background: #353945;
  color: #fff;
}

.nav-pills.gray-tabs .nav-item {
  margin-right: 10px;
}

.nft-user {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: #e6e8ec solid 1px;
}

.highest-bid-box {
  width: 384px;
  max-width: 100%;
  padding: 24px;
  border: #e6e8ec solid 1px;
  border-radius: 16px;
}

.font-14 {
  font-size: 14px;
}

.collection.rounded-20,
.card-img.rounded-20,
.card-img-top.rounded-20,
.rounded-20 {
  border-radius: 20px;
}

.shadow-only {
  box-shadow: #e6e8ec 0 20px 20px;
}

.shadow-box {
  border: #e6e8ec solid 1px;
  border-radius: 24px;
  padding: 34px;
  box-shadow: #e6e8ec 0 20px 20px;
}

.devider {
  border-top: #e6e8ec dotted 1px;
}

.devider span {
  position: relative;
  top: -16px;
  display: inline-block;
  padding: 3px 8px;
  background: #fff;
}

/* --------- modal --------- */
.modal-heading {
  font-size: 32px;
  color: #23262f;
  font-weight: 400;
}

.modal-close-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.modal-icon {
  width: 48px;
  height: 48px;
}

.modal-section-title {
  font-size: 18px;
  color: #23262f;
  font-weight: 400;
}

.modal-section-text {
  font-size: 14px;
  color: #777e90;
  font-weight: 350;
}

.btn.btn-primary.modal-button {
  width: 100%;
  height: 48px;
  border: none;
}

.btn.btn-primary.modal-button:focus {
  border: none !important;
}

.btn.btn-primary.modal-blue-button {
  background-color: #7ca3ff;
}

.btn.btn-primary.modal-grey-button {
  background-color: #b1b5c3;
}

.btn.btn-primary.modal-error-button {
  background-color: transparent;
  border: 1px solid #e6e8ec;
  color: #6d3190 !important;
}

.btn.btn-primary.modal-error-button:hover,
.btn.btn-primary.modal-error-button:focus {
  border: #7e7e7e solid 1px !important;
}

.btn.btn-primary.modal-outline-button {
  background-color: transparent;
  border: 1px solid #e6e8ec;
  color: #23262f !important;
}

.btn.btn-primary.modal-outline-button-dark {
  background-color: #000;
  border: 1px solid #fff;
  color: #fff !important;
}

.btn.btn-primary.modal-outline-button:hover,
.btn.btn-primary.modal-outline-button:focus {
  border: #7e7e7e solid 1px !important;
}

.modal-error-link {
  color: #6d3190;
  text-decoration: none;
}

/* ------------------ */
@media (max-width: 992px) {
  .border-left-purple:hover {
    border-left: 2px solid #6d3190;
    transform: translateX(-2px);
  }
}

.button-icon-grey-40 {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid #e6e8ec;
}

.button-icon-grey-40:hover {
  border: 2px solid #b8babd;
}

.user-social img {
  cursor: pointer;
  margin: 0 16px;
}

.user-social {
  padding-bottom: 32px;
  border-bottom: 1px solid #e6e8ec;
}

.image-64 {
  width: 64px;
}

.border-bottom-1 {
  border-bottom: 1px solid #e6e8ec;
}

.font-600 {
  font-weight: 600;
}

.nft-image {
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

.width-600 {
  max-width: 600px;
}

/* payment page */
.payment-page-label {
  font-size: 18px;
  margin-bottom: 16px !important;
}

.payment-page-input {
  border: #e6e8ec solid 2px !important;
  border-radius: 12px !important;
  height: 48px;
  max-width: 465px;
}

.payment-page-form-group {
  margin-bottom: 36px;
}

.payment-page-cancel-btn {
  color: #000;
  text-decoration: none;
}

.text-purple {
  color: #6d3190;
}

.text-normal {
  font-weight: 350;
  font-size: 14px;
}

.width-100 {
  width: 100%;
}

.large-btn-outline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 3px solid #e6e8ec;
  border-radius: 8px;
  cursor: pointer;
  padding: 12px;
}

.large-btn-outline:hover {
  border: 3px solid #d0cece;
}

.cursor-pointer {
  cursor: pointer;
}

.break-line {
  word-break: break-all;
}

.wallet-list .list-group-item {
  cursor: pointer;
}

.wallet-list .list-group-item:hover {
  background-color: #f0f0f0;
}

.uploadcircle {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #e6e8ec;
  border-radius: 50%;
  height: 48px;
  width: 48px !important;
}

.favourite {
  border: 1px solid gray;
  border-radius: 50%;
  height: 40px;
  width: 40px !important;
}

.nftdetailsone {
  width: 100%;
  line-height: 35px;
}

.content {
  color: black;
  text-align: right;
}

.nftcategory .profileimg {
  height: 48px;
  min-width: 48px;
  border-radius: 50%;
}

.nftcategorysocial {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cardnft {
  background: #fff;
  display: inline-block;
  height: 300px;
  margin: 1rem;
  border-radius: 10px;
  position: relative;
  width: 240px;
}

.card-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.collectionimg {
  height: 85%;
  width: 100%;
  padding: 10px;
  border-radius: 20px;
}

.collectioncontent {
  text-align: center;
}

.collectionBtn {
  position: absolute;
  top: 17px;
  right: 12px;
}

.editbtn {
  border: 2px solid gray;
  padding: 7px;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  background: white;
  font-size: 14px;
}

.editcontainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  /* gap: 10px; */
}

.sidemenuplace {
  display: none;
  z-index: 50;
  background-color: transparent;
  padding: 10px;
  height: auto;
  color: white;
  opacity: 0.8;
}

.sticky {
  position: fixed;
}

.absolute {
  position: absolute;
}

.sidemenuplace.show {
  display: block;
}

.sidemenuplace .main-links {
  width: 135px;
  list-style: none;
}

.rtl .menuhovered img,
.rtl .btn img {
  transform: rotate(180deg);
}

.no-rotate {
  transform: rotate(0deg) !important;
}

.rtl .login .btn img {
  transform: rotate(0deg);
}

.sidemenuplace li {
  font-size: 12px;
  border-radius: 12px;
}

.sidemenuplace a {
  display: block;
  text-decoration: none;
}

.sidemenuplace .sub-links {
  width: 200px;
  list-style: none;
}

.sidemenuplace .sub-links a {
  color: #777e90;
  font-weight: 500;
}

.sidemenuplace .sub-links a:hover {
  color: #6d3190;
}

.menunormal {
  color: #212529;
}

.menuhovered {
  color: #6d3190;
}

.buybtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 20px;
  gap: 12px;
  color: #fcfcfd;
  font-weight: bolder;
  /* width: 112px; */
  height: 38px;
  background: #6d3190;
  border-radius: 90px;
  border: none;
}

.cnclbtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 20px;
  gap: 12px;
  color: #6f6f6f;
  font-weight: bolder;
  width: 112px;
  height: 38px;
  background: #f1f1f1;
  border-radius: 90px;
  border: none;
}

.ownerimg {
  border-radius: 50%;
  background-color: green;
  height: 40px;
  width: 40px;
}

.ownergap {
  border-bottom: 1px solid gray;
  padding: 10px;
}

.historypage {
  color: gray;
  font-size: 13px;
  line-height: 50px;
  border-bottom: 1px solid gray;
}

.historypage .eventicon {
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

.historyHeader {
  font-size: 15px;
  margin-bottom: 10px;
}

.rtl .float-end {
  float: left !important;
}

/* .rtl .btn-group {
  flex-direction: row-reverse !important;
} */
.rtl .btn-group > .btn-group:not(:last-child) > .btn,
.rtl .btn-group > .btn.dropdown-toggle-split:first-child,
.rtl .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rtl .btn-group > .btn-group:not(:last-child):not(:first-child) > .btn,
.rtl .btn-group > .btn:nth-child(n + 3),
.rtl .btn-group > :not(.btn-check) + .btn,
.rtl .btn-group > .btn:not(:last-child):not(:first-child) {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.rtl .btn-group > .btn-group:not(:first-child) > .btn,
.rtl .btn-group > .btn:nth-child(n + 3),
.rtl .btn-group > :not(.btn-check) + .btn {
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.rtl .arrow-right {
  transform: rotate(180deg);
}

.rtl .nft-li-desc {
  /* margin-left: 0;
    margin-right: 5px; */
}

.pagination {
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid #ddd;
}

.pagination a.active {
  background-color: #6d3190;
  color: white;
  border: 1px solid #6d3190;
}

.rtl .dropdown-menu-end {
  right: unset !important;
  left: 0 !important;
}

.rtl .dropdown-menu {
  text-align: start;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.headermenu {
  color: rgba(256, 256, 265, 1);
  font-weight: 400;
  text-decoration: none;
  padding: 10px 17px 4px 17px;
  margin: 0px 10px 0px 10px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  justify-content: center;
  text-align: center;
}

.headermenu:hover {
  color: #6d3190;
  cursor: pointer;
}

.rtl .headermenu {
  letter-spacing: 0;
}

.navbar-inverse .navbar-toggle {
  border-color: red;
}

.navbar-inverse .navbar-toggle .icon-bar {
  background: red;
}

.active {
  border-bottom: 2px solid #6d3190;
}

.upload-popover .popover {
  z-index: 99;
}

.form-check-input {
  width: 20px !important;
}

.form-switch .form-check-input {
  width: 40px !important;
}

.price-select .btn-group > .btn {
  background: #6d6e71 !important;
  border-color: #6d6e71 !important;
}

.btn-group > .btn.active {
  background: #6d3190 !important;
  border-color: #6d3190 !important;
}

.rtl .form-select {
  padding: 0.375rem 0.75rem 0.375rem 2.25rem;
  background-position: left 0.75rem center;
}

.rtl .input-group {
  direction: ltr;
}

.rtl .editcontainer {
  justify-content: left;
}

.rtl .form-check {
  padding-left: 0;
  padding-right: 1.5em;
}

.rtl .form-check .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0em;
}

@media (min-width: 1200px) {
  .rtl .ps-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 0 !important;
  }
}

.btn-border-gradient {
  color: #6d3190;
  font-weight: 350;
  text-decoration: none;
  position: relative;
  border-radius: 50px;
  height: 38px;
  padding: 11px 22px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-border-gradient:hover {
  color: #6d3190;
  opacity: 0.8;
}

.btn-border-gradient::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 2.5px;
  background: linear-gradient(to right, #6d3190, #8e3078, #ff74ad);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.btn-border-gradient-golden {
  color: #b58937;
  font-weight: 350;
  text-decoration: none;
  position: relative;
  border-radius: 50px;
  height: 38px;
  padding: 11px 22px;
  height: 38px;
}

.btn-border-gradient-golden:hover {
  color: #b58937;
  opacity: 0.8;
}

.btn-border-gradient-golden::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 50px;
  padding: 2.5px;
  background: linear-gradient(to right, #b69a66, #b58937, #916e2c);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.uppercase {
  text-transform: uppercase;
}

.aa-Panel {
  z-index: 1000;
}

#autocomplete {
  padding-bottom: 5px;
  /* position:absolute; */
  padding-left: 11.25rem;
  /* padding-right: 12.5rem; */
  width: 100%;
}

.undecorated {
  text-decoration: none;
}

.aa-ClearButton {
  display: none;
}
/* arabic font weight for button and header and home title */

.rtl .bHOXlm td {
  font-weight: 400;
}

.a_tag_collection:hover {
  color: blue;
}

/* activity links */

.activity_link {
  text-decoration: none;
  color: black;
  font-weight: 300;
}

.animated-circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}
.animated-circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}
.animated-circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.animated-shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}
.animated-shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}
.animated-shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.animated-wrapper {
  width: 200px;
  height: 60px;
  /* position: absolute;
  left: 50%;
  top: 50%; */
  transform: translate(0%, -50%);
}
.arrow-buttom {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1;
}
.visaMaster {
  transform: rotate(0deg) !important;
}
.validation {
  color: red;
  font-size: 12px;
  margin-inline-start: 15px;
  font-weight: 325;
}
