h1 {
  padding-top: 50px;
  font-size: 48px;
}
h2 {
  font-size: 60px !important;
  font-weight: 400 !important;
  line-height: 1em;
  color: #000;
  &.purple-title {
    margin-bottom: 60px;
    color: #6d3190;
  }
  &.uppercase {
    text-transform: uppercase;
  }
}
.about-content {
  font-size: 20px;
  color: #858585;
  margin-top: 25px;
}
.user-xlg-rounded {
  width: 120px;
  height: 120px;
  border-radius: 100px;
}
.sup-title {
  font-size: 20px;
  font-weight: 350;
  color: #000;
}
.using-reasons {
  padding-top: 60px;
  height: 700px;
  font-size: 22px;
  font-weight: 400;
  color: #000;
  background: url(../icons/oval.png) center top no-repeat;
  background-size: 100%;
  & > div {
    width: 284px;
    background-color: #fff;
    padding: 10px;
    margin: 0 auto 20px;
  }
}
.step-number {
  font-size: 60px;
  line-height: 1em;
  color: #f2f2f2;
}
.how-box {
  padding: 14px 20px;
  width: 264px;
  border-radius: 4px;
  box-shadow: #e5e5e5 5px 20px 50px;
  & h5 {
    font-size: 16px !important;
    font-weight: bold !important;
    color: #000;
    text-transform: uppercase;
  }
  & img {
    margin-bottom: 30px;
  }
}
