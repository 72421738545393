.marketplaceDropdown {
  display: none;
  position: absolute;
  top: 92px;
  left: 50px;
  z-index: 50;
  background-color: #fff;
  border-radius: 24px;
  padding: 24px;
}
.marketplaceDropdown.show {
  display: block;
}
.marketplaceDropdown .main-links {
  width: 300px;
  list-style: none;
}
.marketplaceDropdown li {
  border-radius: 12px;
}
.marketplaceDropdown li:hover {
  background-color: #f7f7f7;
}
.marketplaceDropdown li:hover > .li-item {
  color: #6d3190;
}

.marketplaceDropdown a {
  display: block;
  text-decoration: none;
  color: #212529;
  font-weight: 600;
}
.marketplaceDropdown .sub-links {
  width: 200px;
  list-style: none;
}
.marketplaceDropdown .sub-links a {
  color: #777e90;
  font-weight: 500;
}
.marketplaceDropdown .sub-links a:hover {
  color: #6d3190;
}
