.edit-page-photo {
  height: 128px;
  width: 128px;
  /* background-color: #6d3190; */
  border-radius: 50%;
  margin-right: 40px;
}
.rtl .edit-page-photo {
  margin-left: 40px;
  margin-right: 0px;
}
.edit-page-title {
  font-size: 40px;
  line-height: 64px;
  font-weight: 400;
  color: #23262f;
  text-transform: capitalize;
}
.edit-page-info {
  font-size: 16px;
  line-height: 24px;
  color: #777e90;
}
.edit-section-title {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #23262f;
}
.edit-section-text {
  margin-top: 5px;
  font-size: 14px;
  line-height: 18px;
  color: #777e90;
  max-width: 200px;
}
.edit-section-label {
  font-size: 14px;
  line-height: 24px;
  font-weight: 350;
  color: #777e90;
  text-transform: uppercase;
}
.breadcrumb-item a {
  color: #777e90;
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #23262f !important;
}
.form-control.edit-page-input-field {
  background: transparent;
  border: #e6e8ec solid 1px;
  border-radius: 8px;
  height: 48px;
  font-weight: 350;
}
.form-control.edit-page-text-area {
  background: transparent;
  border: #e6e8ec solid 1px;
  border-radius: 8px;
  font-weight: 350;
}
.button-icon {
  width: 14px;
  height: 14px;
  margin-right: 12px;
}
.rtl .button-icon {
  margin-left: 12px;
  margin-right: 0px;
}
.clear-all-link {
  text-decoration: none;
  color: #777e90;
}
.clear-all-link:hover img {
  fill: #6d3190;
}
.breadcrumb-section {
  padding: 24px 0;
  border-bottom: 1px solid #e6e8ec;
}
.edit-page-section {
  padding: 96px 0;
}
.title-description {
  margin-bottom: 64px;
}
.update-message {
  border-bottom: 1px solid #e6e8ec;
}
@media (min-width: 992px) {
  .edit-page-section {
    max-width: 900px !important;
  }
}
@media (max-width: 768px) {
  .edit-page-photo {
    height: 96px;
    width: 96px;
    margin-right: 30px;
  }
}
