.rtl {
    & .search-btn {
        left: unset;
        right: -40px;
    }
    & .me-auto {
        margin-left: auto !important;
        margin-right: unset !important;
    }
    & .pe-4 {
        padding-right: unset !important;
        padding-left: 1.5rem !important;
    }
    & .ms-1 {
        margin-left: 0 !important;
        margin-right: 0.25rem !important;
    }
    & .me-1 {
        margin-right: 0 !important;
        margin-left: 0.25rem !important;
    }
    & .me-2 {
        margin-right: 0 !important;
        margin-left: 0.5rem !important;
    }
    & .me-3 {
        margin-right: 0 !important;
        margin-left: 1rem !important;
    }
    & .me-4 {
        margin-left: 1.5rem !important;
        margin-right: unset !important;
    }
    & ul {
        padding-inline-start: 0px;
        padding-inline-end: 40px;
    }
    & .btn.newsletter-btn {
        left: unset;
        right: -50px;
    }
    & .btn.newsletter-btn,
    & .button-icon-grey-40,
    & .collection-dropmenu,
    & .right-arrow-tiny {
        transform: rotate(180deg);
    }
    & .create-and-sell {
        margin-right: 0 !important;
        margin-left: 32px;
    }
    & .notificationsDropdown {
        left: 50px !important;
        right: unset;
    }
    & .userDropdown {
        right: unset;
        left: 20px !important;
    }
    & .marketplaceDropdown {
        margin-left: -200px;
    }
    & .right-arrow-tiny {
        float: left;
    }
    & .right-btn {
        right: unset !important;
        left: -60px;
    }
    & .left-btn {
        left: unset !important;
        right: -60px;
    }
    & .slider .seller-box {
        margin-right: unset;
        margin-left: 32px;
        &:last-child {
            margin-left: 0;
        }
    }
    & .text-lg-start {
        text-align: start !important;
    }
}

@media (min-width: 992px) {
    .rtl {
        .nav-border-left {
            border-right: #e6e8ec solid 1px;
            border-left: unset;
        }
    }
}
